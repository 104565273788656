.dashboard{
}
.dashboardHub{
    grid-template-columns: 48px 1fr;
    background-color: white;

    border: solid 2px gainsboro;
}
.menuBar{
    z-index: 99999999;
    background-color: white;
    border-right: solid 2px gainsboro;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 8px #E2E2E2;
    grid-auto-rows: 48px;
}

.menuBar.expanded{
    width: 300px;
}

.menuBar .option{
    grid-template-columns: 48px 1fr;
}